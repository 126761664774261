import React, { useEffect } from 'react'
import classnames from 'classnames/bind'
import _ from 'lodash'

import { formatRange } from '../lib/time'
import { useAppSelector } from '../lib/hooks'

import TimeRangeInput from '../TimeRangeInput'
import HoursInput from '../HoursInput'
import CodeInput from '../CodeInput'
import { ValidationEntry } from './ValidationMessages'
import NonOverwritingInput from '../NonOverwritingInput'
import styles from '../css/Entry.module.css'
import { IS_TOUCH_DEVICE } from '../lib/config'
import { entryValidationErrors } from '../lib/validation'
import { isSupportedPolicy, PolicyGroup, resolvePolicyGroup, requestInitialMetadata } from '../PolicyGroup'
import { findCurrentContract } from '../lib/selectors'
import { useDispatch } from 'react-redux'

const css = classnames.bind(styles)

const focus = (refToFocus) => {
  if (refToFocus && refToFocus.current && refToFocus.current.focus) {
    refToFocus.current.focus()
  }
}

const navigateKeyboardHorizontal = (ui, dispatch, index, refs) => {
  return (e) => {
    if (index !== ui.keyboardNavigation.entry.index) return

    const refEntries = Object.entries(refs)
    const currentRefIndex = Math.max(0, Object.values(refs).indexOf(refs[ui.keyboardNavigation.entry.column]))
    const currentRef = refs[ui.keyboardNavigation.entry.column]

    if (e.key === 'ArrowRight') {
      const [columnName, ref] = refEntries[Math.min(refEntries.length - 1, currentRefIndex + 1)]
      if (currentRef.current.isBlurrable(e)) {
        e.preventDefault()
        focus(ref)
        dispatch({
          type: 'UI_KEYBOARD_NAVIGATION',
          keyboardNavigation: { entry: { column: columnName, index } },
        })
      }
    } else if (e.key === 'ArrowLeft') {
      const [columnName, ref] = refEntries[Math.max(0, currentRefIndex - 1)]
      if (currentRef.current.isBlurrable(e)) {
        e.preventDefault()
        focus(ref)
        dispatch({
          type: 'UI_KEYBOARD_NAVIGATION',
          keyboardNavigation: { entry: { column: columnName, index } },
        })
      }
    }
  }
}

const useEntryValidation = ({ entry, errorMap, isLastEntry, keyboardNavigation, validation, index }) => {
  // Initial dirty state. This allows us to only validate entries that have been touched by the user.
  const dirty = React.useRef(
    Object.keys(errorMap).reduce((p, c) => {
      return { ...p, [c]: !isLastEntry || entry[c] !== '' }
    }, {})
  )
  const validateEntryColumn = React.useCallback(
    (column, validations) => {
      if (!errorMap[column]) throw Error('Column is not in error map')
      if (!validations) return false
      // Do not validate a column that has not been touched by the user.
      if (!dirty.current[column]) return false
      return _.keys(validations).some((validationErrorCode) => errorMap[column].includes(validationErrorCode))
    },
    [errorMap]
  )

  // Set initial error states
  const [errors, setErrors] = React.useState(
    Object.keys(errorMap).reduce((p, c) => {
      return { ...p, [c]: validateEntryColumn(c, validation) }
    }, {})
  )
  const [validationMessages, setValidationMessages] = React.useState(validation || {})
  React.useEffect(() => {
    const { column: currentColumn, index: currentIndex } = keyboardNavigation.entry
    // Remove any validation errors associated with the currently selected column.
    // We do not want to show these errors until the user has finished editing.
    const validationWithoutSelectedColumn = Object.keys(validation || {})
      .filter((f) => !currentColumn || index !== currentIndex || errorMap[currentColumn].indexOf(f) === -1)
      .reduce((p, c) => {
        return { ...p, [c]: validation[c] }
      }, {})
    // Revalidate the inputs
    const newErrors = Object.keys(errorMap).reduce((p, c) => {
      return { ...p, [c]: validateEntryColumn(c, validationWithoutSelectedColumn) }
    }, {})
    setErrors(newErrors)
    setValidationMessages(validationWithoutSelectedColumn)
    if (index === currentIndex && !dirty.current[currentColumn]) {
      dirty.current[currentColumn] = true
    }
  }, [keyboardNavigation.entry, dirty, errorMap, index, validation, validateEntryColumn])

  return {
    errors,
    validationMessages,
  }
}

export const EditModeEntry = ({
  modifyEntry,
  hourCodes, // InvoiceTaskInfo[]
  entry,
  locale,
  day,
  startEndShown,
  validation,
  isLastEntry,
  index,
  registerFocus,
  ui,
}) => {
  const dispatch = useDispatch()
  const { hours, hourCode, note, startTime, endTime, policyMetadata } = entry || {}
  const isTransient = !hours && !hourCode && !note && !startTime && !endTime

  const contract = useAppSelector((state) => findCurrentContract(state.data.personalHoursClient.contracts, day))
  const hourCodePolicies = useAppSelector((state) => state.data.personalHoursClient.hourCodePolicies)
  const userName = useAppSelector((state) => state.user.dataOwnerUsername)
  const policyGroup = resolvePolicyGroup(entry, day, hourCodes, hourCodePolicies)
  const isHourCodePolicy = isSupportedPolicy(contract, policyGroup, day, userName)

  // for loading old data from server, where metadata is missing.
  useEffect(() => {
    if (!policyMetadata && policyGroup && isHourCodePolicy) {
      modifyEntry({ policyMetadata: requestInitialMetadata(policyGroup, contract) })
    }
  }, [])

  const refs = {
    startTime: React.useRef(),
    endTime: React.useRef(),
    hours: React.useRef(),
    hourCode: React.useRef(),
    note: React.useRef(),
  }

  const { errors, validationMessages } = useEntryValidation({
    entry,
    errorMap: entryValidationErrors,
    isLastEntry,
    keyboardNavigation: ui.keyboardNavigation,
    validation,
    index,
  })

  const showValidation = Object.keys(validationMessages).some((k) => !!validationMessages[k])
  registerFocus(() => {
    const focusElement = ui.keyboardNavigation.entry.column
    if (!IS_TOUCH_DEVICE && focusElement) {
      focus(refs[focusElement])
    } else if (!ui.keyboardNavigation.entry.column) {
      focus(refs[startEndShown ? 'startTime' : 'hours'])
    }
  })

  const focusHandler = (e, ref) => {
    const column = Object.entries(refs).find(([__, ref_]) => ref_ === ref)[0]
    if (ui.keyboardNavigation.entry.index !== index || ui.keyboardNavigation.entry.column !== column) {
      dispatch({
        type: 'UI_KEYBOARD_NAVIGATION',
        keyboardNavigation: {
          entry: {
            column,
            index,
          },
        },
      })
    }
  }

  return (
    <div
      className={css('entry', {
        startEndShown,
        hourCodePolicy: isHourCodePolicy,
      })}
      data-test='entry'
      onKeyDown={navigateKeyboardHorizontal(
        ui,
        dispatch,
        index,
        startEndShown ? refs : _.omit(refs, ['startTime', 'endTime'])
      )}
    >
      {startEndShown && (
        <TimeRangeInput
          startRef={refs.startTime}
          endRef={refs.endTime}
          className={css('item', 'timeRange')}
          onFocus={focusHandler}
          invalidEndTime={errors.endTime}
          invalidStartTime={errors.startTime}
          {...{ modifyEntry, entry, locale }}
        />
      )}
      <HoursInput
        inputRef={refs.hours}
        className={css('item', 'hours')}
        onFocus={focusHandler}
        validationError={errors.hours}
        {...{ modifyEntry, entry }}
      />
      {isHourCodePolicy && startEndShown && (
        <p className={styles.useSameTimeZone}>{locale.texts.hourCodePolicy[policyGroup.name].useSameTimeZone}</p>
      )}
      <CodeInput
        inputRef={refs.hourCode}
        className={styles.item}
        onFocus={focusHandler}
        validationError={errors.hourCode}
        {...{
          entry,
          modifyEntry,
          hourCodes,
          locale,
        }}
      />
      <NoteInput
        inputRef={refs.note}
        className={css('item', 'note')}
        onFocus={focusHandler}
        validationError={errors.note}
        placeholder={isHourCodePolicy ? locale.texts.hourCodePolicy[policyGroup.name].note : undefined}
        {...{
          entry,
          modifyEntry,
          locale,
        }}
      />
      {isHourCodePolicy && <PolicyGroup {...{ policyGroup, entry, locale, modifyEntry, contract, startEndShown }} />}
      {!isTransient && (
        <button
          data-test='entry-delete'
          className={css('delete', { policyGroupDelete: isHourCodePolicy })}
          tabIndex={-1}
          onClick={(e) => {
            e.stopPropagation()
            modifyEntry({ hours: '', hourCode: '', note: '', startTime: '', endTime: '' })
          }}
        >
          <img src='/trash.svg' alt='Delete' />
        </button>
      )}
      {showValidation && (
        <ValidationEntry
          {...{
            validation: validationMessages,
            entry,
            modifyEntry,
            day,
            locale,
            startEndShown,
          }}
        />
      )}
    </div>
  )
}

export const ViewModeEntry = ({ entry, startEndShown, onItemClick, locked, entriesHaveNotes }) => {
  const onClick = (name) => (e) => {
    e.stopPropagation()

    if (!locked) {
      onItemClick(name)
    }
  }

  return (
    <div
      data-test='viewEntry'
      className={css('viewEntryGrid', {
        entryColumnsPlusNoteAndStartEnd: entriesHaveNotes && startEndShown,
        entryColumnsPlusNote: entriesHaveNotes && !startEndShown,
        entryColumnsPlusStartEnd: !entriesHaveNotes && startEndShown,
        entryColumns: !entriesHaveNotes && !startEndShown,
      })}
    >
      {startEndShown && (
        <span
          data-test='viewEntry-time'
          className={css('startEndTimes', {
            locked,
            timeUnlocked: !locked,
          })}
          onClick={onClick('startTime')}
        >
          {formatRange(entry.startTime, entry.endTime)}
        </span>
      )}
      <span
        data-test='viewEntry-hours'
        className={css('entryHours', {
          locked,
          unlocked: !locked,
        })}
        onClick={onClick('hours')}
      >
        {entry.hours} h
      </span>
      <span
        data-test='viewEntry-hourCode'
        className={css('hourCode', {
          locked,
          unlocked: !locked,
        })}
        onClick={onClick('hourCode')}
      >
        {entry.hourCode}
      </span>
      {entry && entry.note && entry.note.length > 0 && (
        <span
          data-test='viewEntry-note'
          className={css('note', {
            locked,
            unlocked: !locked,
          })}
          onClick={onClick('note')}
        >
          {entry.note}
        </span>
      )}
    </div>
  )
}

const NoteInput = ({ entry, locale, className, validationError, modifyEntry, inputRef, onFocus, placeholder }) => {
  return (
    <NonOverwritingInput
      ref={inputRef}
      type='text'
      className={css(className, 'noteInput', { validationError })}
      placeholder={placeholder || locale.texts.entryNotePlaceholder || ''}
      autoComplete='off'
      autoCorrect='off'
      spellCheck='false'
      value={entry.note}
      onFocus={(e) => onFocus && onFocus(e, inputRef)}
      onChange={(e) => {
        const note = e.target.value
        modifyEntry({ note })
      }}
      data-test='entry-note'
    />
  )
}
